import React, { useMemo, useState, useEffect } from 'react';

// Components
import { Container, Segment, Icon } from 'semantic-ui-react';
import { EmojiRenderer } from './EmojiRenderer';

// Styles
import styles from './Footer.module.less';

/*
  本项目基于 AGPL 3.0 许可协议开源。
  在您自行部署本项目时，请保留此处的作者信息。
*/
const millisecondPerDay = 1000 * 60 * 60 * 24;

const Footer: React.FC = () => {
	const [sysTime, setSysTime] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	  });
	let  sys_day, sys_hour, sys_min, sys_sec;

	useEffect(() => {
		const setStartTime = new Date('2024-02-01').getTime();
	
		const updateSysTime = () => {
		  const now = new Date().getTime();
		  const passedTime = now - setStartTime;
		  const restDate = new Date(passedTime % millisecondPerDay);
	
		  setSysTime({
			days: Math.floor(passedTime / millisecondPerDay),
			hours: restDate.getHours(),
			minutes: restDate.getMinutes(),
			seconds: restDate.getSeconds(),
		  });
		};
	
		const intervalId = setInterval(updateSysTime, 1000);
	
		return () => clearInterval(intervalId);
	  }, []);
	
	return (
	<Segment vertical>
		<Container textAlign="center">
		系统已运行 {sysTime.days} 天 {sysTime.hours} 小时 {sysTime.minutes} 分钟 {sysTime.seconds} 秒
		{/* <div className={styles.footerIcons}>
			<code>{window.appVersion.substring(0, 7)}</code>
			<a
			href="https://github.com/renbaoshuo/OIerDb"
			title="GitHub"
			target="_blank"
			rel="noreferrer noopener"
			>
			<Icon name="github" style={{ margin: '0 .25rem 0' }} />
			Source Code
			</a>
		</div> */}
		<div className={styles.footerText}>
			Copyright © 绿旗编程 ｜ 仪征专业编程赛考机构
		</div> 
		</Container>
	</Segment>
)};

export default Footer;
